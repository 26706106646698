import React from 'react'
import { config, useSpring } from 'react-spring'
import Layout from '../components/layout'
import { AnimatedBox } from '../elements'
import SEO from '../components/SEO'

const About = () => {
  const pageAnimation = useSpring({
    config: config.slow,
    from: { opacity: 0 },
    to: { opacity: 1 },
  })

  return (
    <Layout>
      <SEO title="About | Dhamen photography" desc="Hi. I'm Dhamen, a Tunisian Photographer living in Paris." />
      <AnimatedBox style={pageAnimation} py={[6, 6, 6, 8]} px={[6, 6, 8, 6, 8, 13]}>
        <h1>Hi. I'm Dhamen</h1>
        <h2>It’s gonna be great to work together!</h2>
        <p>Right now, I am based in Paris, where I combine my passion for great projects With 12 years of experience in graphic Design .The crossed history of photography and design, allowed me to use my graphic creativity in order to develop my passion for photography.</p>
        <p>My interest in photography led me to take photographic trips and participate in exhibitions in Tunisia and India.</p>
        <p>My job is to combine my set of skills with your ideas to capture memories and tell stories.</p>

        <p>
          You can follow me on <a target="_blank" href="https://www.instagram.com/dhamen.photos/">Instagram</a> or see{' '}
          <a href="/projects">some of my projects</a>.
        </p>
        <p><small>Updated: 21-07-2021</small></p>
      </AnimatedBox>
    </Layout>
  )
}

export default About
